import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import style from '../styles/pages/404.scss';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className={style.errorPage}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  </Layout>
);

export default NotFoundPage;
